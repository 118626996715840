<template>
  <main class="container-fluid">
    <b-card class="af-card pb-5">
      <h3 class="text-14 font-weight-bold text-uppercase mb-4">Transaction History</h3>
      <b-row class="justify-content-between">
        <b-col cols="4" class="mt-4">
          <b-form-group class="af-input">
            <b-input-group class="mb-3">
              <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
              <template v-slot:append>
                <b-input-group-text class="af-input-append">
                  <span class="icon-magnifier"></span>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="text-right mt-4">
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-col>
      </b-row>
      <!-- table -->
      <b-table
        id="af-table"
        :items="items"
        :fields="fields"
        hover
        responsive
        borderless
      >
        <template v-slot:head()="data">
          <span class="text-uppercase title">{{ data.label }}</span>
        </template>

        <template v-slot:cell(transactions)="data">
          <span class="font-weight-bold">{{ data.value }}</span>
        </template>

        <template v-slot:cell(transaction_id)="data">
          <span class="font-weight-bold">{{ data.value }}</span>
        </template>

        <template v-slot:cell(date)="data">
          <span class="text-secondary">{{ data.value }}</span>
        </template>

        <template v-slot:cell(time)="data">
          <span class="text-secondary">{{ data.value }}</span>
        </template>

        <template v-slot:cell(amount)="data">
          <span class="text-success title text-uppercase">₦ {{ data.value }}</span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="right"
        hide-ellipsis
        hide-goto-end-buttons
        aria-controls="af-table"
      >
        <template v-slot:page="{ page, active }">
          <span class="active" v-if="active">{{ page }}</span>
          <span v-else>{{ page }}</span>
        </template>
      </b-pagination>
    </b-card>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'History',
    titleTemplate: '%s | AirtimeFlip'
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10,
      filter: null,
      selected: null,
      items: [
        { transactions: 'Airtime to Cash', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'Bill Payment', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'Airtime to Cash', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'DSTV Payment', transaction_id: '#3069859', amount: '3,600.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'Airtime to Cash', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'Bill Payment', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'Airtime to Cash', transaction_id: '#3069859', amount: '1,000.00', date: '05.11.2019', time: '03:39 PM' },
        { transactions: 'DSTV Payment', transaction_id: '#3069859', amount: '3,600.00', date: '05.11.2019', time: '03:39 PM' }
      ],
      fields: [
        'transactions',
        'transaction_id',
        'amount',
        'date',
        'time'
      ],
      options: [
        { value: null, text: 'Sort by' },
        { value: 'a', text: 'By Date' },
        { value: 'b', text: 'Paid Transactions' },
        { value: 'c', text: 'Pending Transactions' },
        { value: 'd', text: 'Declined Transactions' }
      ]
    }
  },
  computed: {
    rows () {
      return this.items.length
    }
  }
}
</script>

<style>
.hidden_header {
  display: none;
}
</style>
